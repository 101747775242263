import produce from "immer";

import {
  CHECK_ADVERT,
  CHECK_ALL_ADS,
  UNCHECK_ALL_ADS,
  CHECK_ALL_ADS_FROM_PAGE,
  SAVE_ADVERTS,
} from "../actions/action-types";

const INITIAL_STATE = {
  advertsArray: {},
  allChecked: false,
  pagesAllChecked: false,
};

export default function advertsSelectionReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CHECK_ADVERT: {
        const { id, checked, status } = action.payload;
        draft.advertsArray[id] = {
          ...draft.advertsArray[id],
          id,
          checked,
          status,
        };
        break;
      }
      case SAVE_ADVERTS: {
        Object.values(action.payload).map((item) => {
          if (draft.advertsArray[item.external_id]) {
            return (draft.advertsArray[item.external_id] = {
              id: item.external_id,
              checked: draft.advertsArray[item.external_id].checked,
              account_name: item.external_name,
              account_id: item.account_id,
              status: item.status,
            });
          }
          return (draft.advertsArray[item.external_id] = {
            id: item.external_id,
            checked: draft.allChecked,
            account_name: item.external_name,
            account_id: item.account_id,
            status: item.status,
          });
        });
        break;
      }
      case CHECK_ALL_ADS: {
        draft.advertsArray = {};
        draft.allChecked = true;
        draft.pagesAllChecked = false;
        break;
        
      }
      case UNCHECK_ALL_ADS: {
        return INITIAL_STATE;
      }

      case CHECK_ALL_ADS_FROM_PAGE: {
        const { adverts } = action.payload;

        Object.values(adverts).map((item) => {
          return (draft.advertsArray[item.external_id] = {
            id: item.external_id,
            checked: true,
            account_id: item.account_id,
            account_name: item.external_name,
            status: item.status,
          });
        });

        draft.allChecked = false;
        draft.pagesAllChecked = true;
        break;
      }
      default:
        return state;
    }
  });
}
